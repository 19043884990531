import React from "react";
import CogTitleItems from "../components/CogTitleItems";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const StyledCogTitleItems = styled(CogTitleItems)`
    .description {
        color: #34495E;
        font-weight: 600;
    }
`;

const Campioni = () => {
    return (
        <StyledCogTitleItems
            bgColor="#f1f1f1"
            className="mt-2 pb-5"
            id='section-campioni'
        >
            <div className="row container mx-auto g-5">
                <div className="col-12 col-md-3">
                    <StaticImage src="../assets/images/campione_della_crescita.png" alt="Campioni" placeholder="blurred" layout="fullWidth" />
                </div>
                <div className="col-12 col-md-9 py-3">
                    <h2 className="text-center text-md-start mb-4 fw-bold">PrestitiFaidaTe.it Web Company di credito online,<br /> Campione della Crescita 2024</h2>
                    <p className="text-center text-md-start my-3 description">Campioni della crescita per il 2024</p>
                    <p className="text-center text-md-start my-3">
                        Grazie all’approccio innovativo al mercato e all’attenzione verso la responsabilità sociale d’impresa, 
                        PrestitiFaidaTe.it ha raggiunto traguardi tanto ambiziosi quanto sfidanti.
                    </p>
                    <p className="text-center text-md-start my-3">
                        I riconoscimenti ricevuti ne testimoniamo altresì l’impegno e la vision, di un’impresa volta costantemente 
                        alla massima soddisfazione dei clienti.
                    </p>
                </div>
            </div>
        </StyledCogTitleItems>
    );
}

export default Campioni;
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import DefaultButton from "../components/buttons/DefaultButton";
import Campioni from "../sections/Campioni";
import FormMutui from "../components/FormMutui";

const StyledWpPage = styled.div`
  .wp-page {
    &__content {
      a {
        color: #4066af;
      }
      ul {
        list-style: none;
      }
      .card {
        border: 0;
      }

      ul li::before {
        content: "•";
        color: #e7c700;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 1.5em;
      }

      .nav-tabs li::before {
        display: none;
      }
    }
    &__title {
      text-align: center;
      @media (min-width: 992px) {
        padding-top: 200px;
      }
    }

    &__slug {
      font-size: 0.9em;
      @media (min-width: 768px) {
        max-width: 50%;
        font-size: 20px;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      background-color: #fff;

      @media (min-width: 992px) {
        background-color: ${(props) => props.color || "#F6E99C"};
        height: 300px;
      }
      &__description {
        text-align: center;

        @media (min-width: 992px) {
          text-align: left;
          margin-left: 20px;
          max-width: 40%;
        }

        @media (min-width: 992px) {
          visibility: hidden;
        }

        @media (min-width: 1440px) {
          visibility: visible;
        }
      }

      .intro {
        color: #527191;

        a {
          text-decoration: none;
          color: #527191;
        }

        .text-dark-blue {
          color: #527191;
        }
        .fw-boldest {
          font-weight: 900;
        }
      }

      .disclaimer {
        font-size: 9px;
      }
    }
  }

  .wrapper {
    height: 100%;
    max-width: 1440px;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    &__image-description {
      height: 100%;
      width: 100%;
      align-content: center;
      align-items: center;
    }
  }

  .form-multi-step {
    position: absolute;
    right: 0;
    top: 50px;
  }

  .section {
    display: flex;
    align-items: center;
    padding-bottom: 3em;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
    }
  }
`;

export default function WpPage({ data }) {
  const [intro, setIntro] = useState(null);
  const [color, setColor] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [disclaimer, setDisclaimer] = useState(null);
  const page = data.allWpPage.nodes[0];
  const [isMutui] = useState(page.slug === "mutui-casa" || (page.ancestors && page.ancestors.nodes[0].slug === "mutui-casa"));

  useEffect(() => {
    const sectionIntro = document.querySelector("#section__intro");
    const subTitle = document.querySelector("#_gatsby_page__subtitle_");
    const disclaimer = document.querySelector("#_gatsby_page__disclaimer_");

    setIntro(sectionIntro && sectionIntro.textContent);

    setSubTitle(subTitle && subTitle.innerHTML);

    setDisclaimer(disclaimer && disclaimer.innerHTML);

    setColor(sectionIntro && sectionIntro.dataset.color);
  }, []);

  let form = null;
  

  if (isMutui) {
    form =  <FormMutui className="d-none d-lg-block" />
  } else {
    form = <form
      id="form-multi-step"
      action={process.env.GATSBY_FORM_ACTION}
      method="POST"
      className="form-multi-step d-none d-lg-block"
    ></form>;
  }

  return (
    <Layout helmetTitle={page.seo.title} helmetDescription={page.seo.metaDesc} mutui={isMutui}>
      <StyledWpPage id="page-content" color={color}>
        <div className="wp-page__header">
        <span id="form-mutui" />
          <div className="wrapper container d-flex align-items-center position-relative">
            <div className="d-flex align-items-center flex-column-reverse flex-lg-row wrapper__image-description">
              {page.featuredImage && (
                <GatsbyImage
                  image={getImage(page.featuredImage.node.localFile)}
                  className="img-fluid"
                  alt="Thumbnail"
                />
              )}
              <div className="d-flex flex-column wp-page__header__description">
                {/* <h3 className="fw-bold">{page.title}</h3> */}
                <div className="intro fs-4" dangerouslySetInnerHTML={{ __html: subTitle || intro }}></div>
                <small className="disclaimer text-secondary text-uppercase" dangerouslySetInnerHTML={{ __html: disclaimer }}></small>
                {/* {
                  page.title.toLowerCase() !== 'trasparenza' &&
                  <div className="mt-1">
                    <Link to="/trasparenza/" className="text-secondary fs-6 text-decoration-none"><small>* <span className="text-decoration-underline">Leggi le note di trasparenza</span></small></Link>
                  </div>
                } */}
              </div>
            </div>
            {form}
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="wp-page__slug mb-5 mt-4 fs-5">
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            {page.ancestors && (
              <span>
                {" "}
                &gt;{" "}
                <Link to={"/" + page.ancestors.nodes[0].slug + "/"}>
                  {" "}
                  {page.ancestors.nodes[0].title}
                </Link>{" "}
              </span>
            )}{" "}
            &gt; <Link to={"/" + page.slug + "/"}>{page.title}</Link>
          </div>
          <h1 className="wp-page__title mb-5 display-5 fw-bold">
            {page.title}
          </h1>
          <div
            className="wp-page__content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
        <DefaultButton
          title={isMutui ? "RICHIEDI IL TUO MUTUO" : "RICHIEDI IL TUO PRESTITO"}
          className="mt-5 mb-5 btn-outline-dark d-none d-lg-block mx-auto"
          url={isMutui ? "#form-mutui" : "#form-multi-step"}
          id={isMutui ? "richiedi-mutuo" : "richiedi-prestito"}
        />
      </StyledWpPage>
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-2"
      />
      {page.slug === 'chi-siamo' && <Campioni />}
      <DipendentePensionatoPrivato />
      <SliderOne />
      <MetodoPiuVeloce className="mt-2" />
      <CessioneFinanziamenti className="mb-5" />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 75
                  layout: FIXED
                )
              }
            }
          }
        }
        ancestors {
          nodes {
            ... on WpPage {
              title
              slug
            }
          }
        }
        seo {
          metaDesc
          title
          opengraphTitle
          metaKeywords
          twitterDescription
          twitterTitle
        }
      }
    }
  }
`;
